import { NgModule } from "@angular/core";
import { RouterModule, Routes, Router, PreloadAllModules } from "@angular/router";
import { TraceService as SentryTraceService } from "@sentry/angular";
import { ConfigResolver } from "src/app/data/resolvers/config.resolve";
import { PartialDataResolver } from "src/app/data/resolvers/partialdata.resolve";
import { ProtectJSResolve } from "src/app/data/resolvers/protectJS.resolve";
import { CanDeactivateGuard } from "src/app/data/services/can-deactivate/can-deactivate.guard";
import { LaunchDarklyResolver } from "../data/resolvers/LaunchDarkly.resolve";

//  Components Imports
import { MainWrapperComponent } from "src/app/components/main-wrapper/main-wrapper.component";
import { WelcomeComponent } from "../screens/welcome/welcome.component";
import { SuccessComponent } from "src/app/screens/success/success.component";
import { PageNotFoundComponent } from "../screens/page-not-found/page-not-found.component";
import { BlankLoadingPageComponent } from "../components/utility/blank-loading-page/blank-loading-page.component";
import { PaytraceInitResolver } from "../data/resolvers/paytaceInit.resolve";
import { CustomPreloadingStrategy } from "../data/services/custom.preloadStrategy";

const url = window.location.href;

let route: string;

if (url.includes("payviam")) {
  route = "activate/itm";
} else if (url.includes("bestpass")) {
  route = "activate/owner-op";
} else {
  route = "activate/itm";
}

const routes: Routes = [
  {
    path: "activate/:vendorId",
    component: MainWrapperComponent,
    resolve: { config: ConfigResolver, partial: PartialDataResolver, flag: LaunchDarklyResolver },
    canDeactivate: [CanDeactivateGuard],
    children: [
      { path: "", component: MainWrapperComponent, pathMatch: "full" },
      { path: "welcome", component: WelcomeComponent },
      { path: "loading-page", component: BlankLoadingPageComponent }, 
      { path: "service-plan", redirectTo: "welcome"},
      {
        path: "contact",
        loadChildren: () =>
          import("./contact-info/contact-info.module").then(
            (m) => m.ContactInfoModule
          ),
      },
      {
        path: "coverage",
        loadChildren: () =>
          import("./coverage-module/coverage.module").then((m) => m.CoverageModule),
      },
      {
        path: "vehicle",
        loadChildren: () =>
          import("./vehicles/vehicles.module").then((m) => m.VehiclesModule),
      },
      {
        path: "trailer",
        loadChildren: () =>
          import("./trailer/trailer.module").then((m) => m.TrailerModule),
      },
      {
        path: "ORDP",
        loadChildren: () =>
          import("./ORDP-module/ORDP.module").then((m) => m.ORDPModule),
      },
      {
        path: "extras",
        resolve: {
          script: PaytraceInitResolver
        },
        loadChildren: () =>
          import("./extras-module/extras.module").then((m) => m.ExtrasModule),
      },
      {
        path: "support",
        loadChildren: () =>
          import("./support-module/support.module").then((m) => m.SupportModule),
      },
      {
        path: "payment",
        resolve: {
          script: PaytraceInitResolver,
          protectJS: ProtectJSResolve,
        },
        loadChildren: () =>
          import("./payment-module/payment.module").then(
            (m) => m.PaymentModule
          ),
      },
      {
        path: "vin2vrc",
        loadChildren: () =>
          import("./VIN2VRC-module/VIN2VRC.module").then((m) => m.VIN2VRCModule),
      },
      { path: "success", component: SuccessComponent },
      { path: "**", component: PageNotFoundComponent },
    ],
  },
  { path: "**", redirectTo: route, pathMatch: "full" },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      enableTracing: false,
      scrollPositionRestoration: "top",
      preloadingStrategy: CustomPreloadingStrategy
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: SentryTraceService,
      deps: [Router],
    },
  ],
})
export class AppRoutingModule {}
