import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
interface ProxyRequest {
  url: string,
  payload: any,
  vendor?: string
}

interface ProxyResponse {
  payload: string,
  status: number,
  message: string
}

@Injectable({
  providedIn: 'root'
})
export class OnboardingApiService {
  // To avoid loading of script to app multiple times.
  private googleMapScriptLoaded = false;
  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  postSpreadsheetValidation(data: BinaryType): Promise<any> {

    const httpOptions = new HttpHeaders({
      'Content-Type':  'application/vnd.ms-excel'
    });

    const options = { responseType: "blob" as "json", headers: httpOptions };
    const url = environment.onboardingAPI + "/SpreadsheetValidation";

    return this.http.post<any>(url, data, options).toPromise()
  }

  getGooglePlacesScript(): Promise<string> {
    if(this.googleMapScriptLoaded) {
      return Promise.reject('already loaded');
    }

    this.googleMapScriptLoaded = true;
    const request = {
      url: environment.googleMapsUrl,
      payload: ''
    }

    return this.postProxyPassthrough(request)
  }

  async getLicensePlateData(payload: any): Promise<any>{
    const request = {
      url: `${environment.bestpassUrl}/Vehicle/GetLicensePlateEligibility`,
      payload: payload
    }

    try {
      const response = await this.postProxyPassthrough(request)
      const parsedResponse = JSON.parse(response)
      return parsedResponse;
    } catch(error) {
      console.warn(error);
    }
  }

  async createAccount(payload: any): Promise<string> {
    const request = {
      url: environment.bestpassUrl + '/accounts',
      payload: payload
    }

    const response = await this.postProxyPassthrough(request);
    let parsedResponse;
      try {
        parsedResponse = JSON.parse(response);
      } catch (innerError) {
          console.warn("Error parsing response:", innerError);
          parsedResponse = {
            status:40000,
            StatusMessage: "Invalid response format."
          };
      }
    const accountNumber = parsedResponse?.AccountNumber

    if (!accountNumber) {
      throw new HttpErrorResponse({
        status: parsedResponse?.ResponseCode,
        error: parsedResponse?.StatusMessage,
      })
    }

    return accountNumber
  }

  async addVehicle(payload: any): Promise<void> {

    const request = {
      url: `${environment.bestpassUrl}/accounts/AddVehicles`,
      payload
    }
    try{
    // triggers http call
    const response = await this.postProxyPassthrough(request);
    return JSON.parse(response);
   }
   catch(error){
    console.warn(error);
   }
  }

  submitPayment(
    accountNumber: string,
    paymentProvider: string,
    paymentDetails: any,
    billingAddress: any
  ): Promise<void> {
    const request = {
      url: `${environment.bestpassUrl}/accounts/${accountNumber}/paymentMethod`,
      payload: {
        Payload: {
         ...paymentDetails
        },
        AccountNumber: accountNumber,
        Provider: paymentProvider,
        BillingAddress: billingAddress
      }
    }

    return this.postProxyPassthrough(request as unknown as ProxyRequest)
  }

  submitPaymentFromProfile(
    accountNumber: string,
    paymentProvider: string,
    paymentDetails: any,
    billingAddress: any,
    pciProfileResponse: any,
    tollPlanId: number
  ): Promise<void> {
    const request = {
      url: `${environment.bestpassUrl}/accounts/${accountNumber}/setInitialPaymentMethodFromProfile`,
      payload: {
        TollPlanId: tollPlanId,
        CustomerProfileResponse: pciProfileResponse,
        PaymentProfileRequest: {
          Payload: {
            ...paymentDetails
          },
          AccountNumber: accountNumber,
          Provider: paymentProvider,
          BillingAddress: billingAddress
        }
      }
    }

    return this.postProxyPassthrough(request as unknown as ProxyRequest)
  }

  private async postProxyPassthrough(request: ProxyRequest): Promise<any> {
    const httpOptions = new HttpHeaders({
      'Content-Type':  'application/json'
    });

    const options = { responseType: "blob" as "json", headers: httpOptions };
    const url = environment.onboardingAPI + "/RequestMapper";

    let vendor;

    if( request.vendor != null && request.vendor.length > 0 ){
      vendor = request.vendor
    }
    else{

      vendor = this.router.url
      .match(/activate\/[^\/]{1,}/g)[0]
      .replace('activate/', '')
    }



    let requestWithVendor = {
      vendor: vendor,
      ...request
    }

    let URLSplit = request?.url?.split("/");

    if ((vendor === 'itm' && request?.payload?.CreateAccountPayload?.oem == "mercedes" && URLSplit && URLSplit[URLSplit.length - 1] == "SubmitAccountAndPayment")
      || (vendor === 'itm' && request?.payload?.oem == "mercedes" && URLSplit && URLSplit[URLSplit.length - 1] == "accounts")) {
      requestWithVendor.vendor = 'mercedes'
    }
    const response = await this.http.post<ProxyResponse | Blob>(url, requestWithVendor, options).toPromise()

    let proxyResponse: ProxyResponse;

    if (response instanceof Blob) {
      const blobAsString = await this.readBlobText(response)

      proxyResponse = JSON.parse(blobAsString) as ProxyResponse

    } else {
      proxyResponse = response
    }

    // If status is not 200 level, throw http error from embedded error details
    if (proxyResponse.status < 200 || proxyResponse.status >= 300) {
      throw new HttpErrorResponse({
        status: proxyResponse.status,
        error: proxyResponse.payload,
        statusText: proxyResponse.message
      })
    }

    return proxyResponse.payload
  }

  postStorageHandler(request: object): void {
      const url = environment.onboardingAPI + "/StorageHandler";

      // triggers http call
      this.http.post(url, request);
  }

  async postPartialCustomerData(payload: object): Promise<any> {
    const request = {
      url: `${environment.bestpassUrl}/CustomerData/SaveCustomer`,
      payload
    }
    try{
    // triggers http call
    const response = await this.postProxyPassthrough(request);
    return JSON.parse(response);
   }
   catch(error){
    console.warn(error);
   }
}

async saveContactDetails(payload: object): Promise<any> {
  const request = {
    url: `${environment.bestpassUrl}/CustomerData/SaveOOSignupsProspectData`,
    payload
  }
  try{
  // triggers http call
  const response = await this.postProxyPassthrough(request);
  return JSON.parse(response);
 }
 catch(error){
  console.warn(error);
 }
}

  async getVendorConfig(vendorID: string): Promise<any>{

      //endpoint expects response property named "email"

      let string_config = await this.postProxyPassthrough({ url: environment.vendorConfigUrl , payload:null, vendor: vendorID });

      return JSON.parse(string_config);
  }

  getVINData(VIN: string): Observable<any>{


    const url = `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${VIN}?format=json`;

    const response = this.http.get<any>(url);

    return response;

  }

  getPartialData(UUID: string): Observable<any>{

    //endpoint expects response property named "email"

      const url = environment.onboardingAPI + "/PartialDataHandler?UUID=" +UUID;

      const response = this.http.get<any>(url);

      return response;
  }
  getPaytraceClientKey(recaptchaToken: string): Promise<any>{

    const url = `${environment.onboardingAPI}/AuthenticatePaytrace?recaptchaToken=${encodeURIComponent(recaptchaToken)}`;

    const response = this.http.get<any>(url);

    return response.toPromise();
  }

  getRecaptchaSiteKey(): Promise<any>{
    const url = `${environment.onboardingAPI}/GetRecaptchaSiteKey`;

    const response = this.http.get<any>(url);

    return response.toPromise();
  }

  readBlobText(blob: Blob): Promise<string> {

    return new Promise((resolve) => {

      let fr = new FileReader();

      fr.onload = () => {
        resolve(fr.result as string)
      };

      fr.readAsText(blob);

    })
   }

  async validateDeviceId(deviceId: string, isCanandianServicePlan: boolean): Promise<ProxyResponse> {
    const partialEndpointUrl = isCanandianServicePlan ? '/Device/canadianDeviceValue/':'/Device/eligibleWithExceptions/'
    const request = {
      vendor: 'itm',
      url: environment.bestpassUrl + partialEndpointUrl + deviceId,
      payload: ''
    }

    try {
      const response = await this.postProxyPassthrough(request)
      return JSON.parse(response);
    } catch(error) {
      let parsedError;
      try {
          parsedError = JSON.parse(error.error);
      } catch (innerError) {
          console.warn("Error parsing error response:", innerError);
          parsedError = { message: "Invalid error response format." };
      }
        throw new HttpErrorResponse({
          status: error.status,
          error: isCanandianServicePlan?parsedError:error,
        })
    }
  }

  async getVehicleYearsByVehicleMakeId(makeId: number): Promise<any> {
    const url = environment.bestpassUrl + '/Vehicle/GetVehicleYearsByVehicleMakeId/vehicleMakeId/' + makeId;

    try {
      const response = await this.postProxyPassthrough({url, payload: null})
      return JSON.parse(response);
    } catch(error) {
      let parsedError;
      try {
          parsedError = JSON.parse(error.error);
      } catch (innerError) {
          console.warn("Error parsing error response:", innerError);
          parsedError = { message: "Invalid error response format." };
      }
      throw new HttpErrorResponse({
        status: error.status,
        error: parsedError,
      })
    }
  }

  async getVehicleModelsByVehicleYearId(yearId: number, segmentId: number): Promise<any> {
    const url = environment.bestpassUrl + '/Vehicle/GetVehicleModelsByVehicleYearId/vehicleYearId/'+ yearId +'/vehicleSegmentId/' + segmentId;

    try {
      const response = await this.postProxyPassthrough({url, payload: null})
      return JSON.parse(response);
    } catch(error) {
      let parsedError;
      try {
          parsedError = JSON.parse(error.error);
      } catch (innerError) {
          console.warn("Error parsing error response:", innerError);
          parsedError = { message: "Invalid error response format." };
      }
      throw new HttpErrorResponse({
        status: error.status,
        error: parsedError,
      })
    }
  }

  async signupAssociatedPortal(payload: any): Promise<any> {
    const url = environment.bestpassUrl + '/accounts/SignupAssociatedPortal';
    try {
      const response = await this.postProxyPassthrough({url, payload})
      return JSON.parse(response);
    } catch(error) {
      let parsedError;
      try {
          parsedError = JSON.parse(error.error);
      } catch (innerError) {
          console.warn("Error parsing error response:", innerError);
          parsedError = { message: "Invalid error response format." };
      }
      throw new HttpErrorResponse({
        status: error.status,
        error: parsedError,
      })
    }
  }

  createPciProfile(
    paymentProvider: string,
    paymentDetails: any,
  ): Promise<string> {
    const request = {
      url: `${environment.bestpassUrl}/accounts/CreatePciCustomerProfile`,
      payload: {
        Payload: {
         ...paymentDetails
        },
        AccountNumber: '',
        Provider: paymentProvider,
        BillingAddress: paymentDetails.billing_address
      }
    }

    return this.postProxyPassthrough(request as unknown as ProxyRequest);
  }

  async getDataForPrepop(customerKey: string): Promise<any> {
    const request = {
      url: `${environment.bestpassUrl}/Prepop/GetITMPrepopAndVehicleData`,
      payload: { customerKey }
    }
    try {
      const response = await this.postProxyPassthrough(request);
      return JSON.parse(response);
    } catch(error) {
      let parsedError;
      try {
          parsedError = JSON.parse(error.error);
      } catch (innerError) {
          console.warn("Error parsing error response:", innerError);
          parsedError = { message: "Invalid error response format." };
      }
      throw new HttpErrorResponse({
        status: error.status,
        error: parsedError,
      })
    }
  }

  async getDataForPartialSignup(customerKey: string): Promise<any> {
    const request = {
      url: `${environment.bestpassUrl}/CustomerData/GetCustomerData`,
      payload: { customerKey }
    }

    try {
      const response = await this.postProxyPassthrough(request);
      return JSON.parse(response);
    } catch(error) {
      let parsedError;
      try {
          parsedError = JSON.parse(error.error);
      } catch (innerError) {
          console.warn("Error parsing error response:", innerError);
          parsedError = { message: "Invalid error response format." };
      }
      throw new HttpErrorResponse({
        status: error.status,
        error: parsedError
      })
    }
  }

  async getDataForOOProspectSignup(customerKey: string): Promise<any>{
    const request = {
      url: `${environment.bestpassUrl}/CustomerData/GetOOSignUpCustomerData`,
      payload: { customerKey }
    }

    try{
      const response = await this.postProxyPassthrough(request);
      return JSON.parse(response);
    } catch(error) {
      let parsedError;
      try {
          parsedError = JSON.parse(error.error);
      } catch (innerError) {
          console.warn("Error parsing error response:", innerError);
          parsedError = { message: "Invalid error response format." };
      }
      throw new HttpErrorResponse({
        status: error.status,
        error: parsedError
      })
    }
  }

  async removeSalesforceRecord(customerKey: string, accountNumber: string): Promise<string> {
    const request = {
      url: `${environment.bestpassUrl}/accounts/DeleteSalesforceRecord`,
      payload: { customerKey, accountNumber }
    }
    try {
      const response = await this.postProxyPassthrough(request)
      return JSON.parse(response);
    } catch(error) {
      let parsedError;
      try {
          parsedError = JSON.parse(error.error);
      } catch (innerError) {
          console.warn("Error parsing error response:", innerError);
          parsedError = { message: "Invalid error response format." };
      }

      throw new HttpErrorResponse({
        status: error.status,
        error: parsedError,
      })
    }
  }
  async submitPaymentAndCreateAccount(payload: any): Promise<string> {
    const request = {
      url: environment.bestpassUrl + '/accounts/SubmitAccountAndPayment',
      payload: payload
    }

    const response = await this.postProxyPassthrough(request)
    let parsedResponse;
    try {
      parsedResponse = JSON.parse(response);
    } catch (innerError) {
        console.warn("Error parsing response:", innerError);
        parsedResponse = {
          status:40000,
          StatusMessage: "Invalid response format."
        };
    }
    const accountNumber = parsedResponse?.AccountNumber

    if (!accountNumber) {
      throw new HttpErrorResponse({
        status: parsedResponse?.ResponseCode,
        error: { message: parsedResponse?.StatusMessage, retryAllowed: parsedResponse?.RetryAllowed },
      })
    }
    return parsedResponse;
  }

  async getAssociationData(shorterName: string): Promise<any> {
    const request = {
      url: `${environment.bestpassUrl}/accounts/GetAssociation`,
      payload: {
        AssociationShortName: shorterName
      }
    }
    try {
      const response = await this.postProxyPassthrough(request);
      return JSON.parse(response);
    } catch(error) {
      console.warn(error);
    }
  }

  async readTextFromImage(base64: any, readtext: string): Promise<any> {
    const request = {
      url: `${environment.bestpassUrl}/CustomerData/ReadTextFromImage`,
      payload: {
        Base64: base64,
        File: null,
        ReadText: readtext 
      }
    }
    try {
      const response = await this.postProxyPassthrough(request);
      return JSON.parse(response);
    } catch(error) {
      let parsedError;
      try {
          parsedError = JSON.parse(error.error);
      } catch (innerError) {
          console.warn("Error parsing error response:", innerError);
          parsedError = { message: "Invalid error response format." };
      }
      throw new HttpErrorResponse({
        status: error.status,
        error: parsedError
      })
    }
  }

  async getVRCDataByVin(vin: string): Promise<any> {
    const request = {
      url: `${environment.bestpassUrl}/CustomerData/GetVRCData`,
      payload: {
        vin: vin
      }
    }
    try {
      const response = await this.postProxyPassthrough(request);
      return JSON.parse(response);
    } catch(error) {
      let parsedError;
      try {
          parsedError = JSON.parse(error.error);
      } catch (innerError) {
          console.warn("Error parsing error response:", innerError);
          parsedError = { message: "Invalid error response format." };
      }
      throw new HttpErrorResponse({
        status: error.status,
        error: parsedError
      })
    }
  }


}
