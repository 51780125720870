import { TrailerType, VehicleType } from './output';

export interface Config {
  vendorId: string;
  vendorName: string;
  vehicleSegmentId?: number;
  googleTagManager?: {
    devaudi: string;
    devhw: string;
    test: string;
    prod: string;
  };
  inspectLetWid?: {
    devaudi: string;
    devhw: string;
    prod: string;
  };
  logRocketProjectId?: {
    devaudi: string;
    devhw: string;
    test: string;
    prod: string;
  };
  screens: Page[];
  styles: CustomStyles;
  header: string;
  contractPaymentMethod: boolean;
  deferTermsOfServiceAgreement: boolean;
  footer: string;
  termsAndConditions: string;
  ordpTermsAndConditions: string;
  mercedesTermsAndConditions: string;
  ordpLearnMore: string;
  ticketsCovered:string;
  /*
  servicePlan will store the service plan that the user will end up enrolling in
  If the user opts for the bypass option then the bypass plan will be stored in
  service plan while the base service plan (w/o bypass) will be stored in baseServicePlan
  */
  servicePlan: ServicePlan;
  baseServicePlan: ServicePlan;
  newAccountGuidelines?: string;
  noVehicleFlowAccountGuidelines?: string;
  newCustomerPortalUrl?: string;
  bennettdrivewyze?: string;
  steps?: [{
    id: string,
    title: string,
    page: string,
    PageGroup: string[]
  }];
  stepsFlowB?: [{
    id: string,
    title: string,
    page: string,
    PageGroup: string[]
  }];
  stepsFlowC?: [{
    id: string,
    title: string,
    page: string,
    PageGroup: string[]
  }];
  canadianSteps?: [{
    id: string,
    title: string,
    page: string,
    PageGroup: string[]
  }];
  support?:{
    text:string,
    html:string
  };
  comDataLink?: string;
  promotionBanner?: string;
  paymentHeader?: string;
  phoneNumber?: string;
  faqOemsUrl?: string;
  instructionsByOemText?: string;
  successPopup?: {
    heading: string,
    popupTitle: string,
    popupSubtitle: string,
    popupContent: string
  }
  servicePlanStyles?: {
    color?: {
      titleTextActive?: string,
      titleTextInactive?: string,
      planBgHover?: string,
    }
  }
}

export interface Page {
  key: string;
  name: string;
  alias: string;
  next: string | StringPropertyObject;
  progress: string;
  progressFlowB?: string;
  bannerText: string;
  bannerSubText: string;
  bannerIcon: string;
  bannerIconRight: string;
  bannerMobileIcon: string;
  bannerMobileIconLeft: string;
  ShippingTypePriority?: number;
  shippingTypeExpedited?: number;
  shippingSpeedPriorityFees?: number;
  shippingSpeedExpeditedFees?: number;
  header?: string;
  subHeader?: string;
  fees?: number;
  flatFee?: number;
  paragraph?: string;
  label1?: string;
  label2?: string;
  label3?: string;
  label4?: string;
  label5?: string;
  label6?: string;
  label7?: string;
  label8?: string;
  servicePlans?: ServicePlan[];
  bypassServicePlans?: ServicePlan[];
  ccHeader?: string;
  ccInstructions?: string;
  showCompanyName?: boolean;
  showPhoneSmsOptIn?: boolean;
  showDeviceId?: boolean;
  footer?: string;
  backgroundImage?: {
    lg: string;
    sm?: string;
    md?: string;
  };

  // For welcome page dynamic content
  content?: string;
  redirectTo?: string;
  buttonText?: string;
  oems?: {
    oemId: number;
    name: string;
    idLocationInstructions?: [];
    servicePlans: ServicePlan[];
  }[];
  idLocationText: string;
  metaTitle?: string;
  saveReturn?: string;
  truckerImg?: string;
  closePopUpUrl?: string;
  popupText?: string;
}

export interface CustomStyles {
  primaryColor: string;
  backgroundColor: string;
  secondaryColor?: string;
  buttonTextColor?: string;
  activeInputColor?: string;
  buttonDisabledColor?: string;
  footerBackgroundColor?: string;
  tooltipBackgroundColor?: string;
  toggleBackgroundColor?: string;
  matProgressBarBuffer?: string;
  selectedBackgroundColor?: string;
  saveProgressButtonColor?: string;
  stepperProgressColor?: string;
}
export interface ServicePlan {
  annualFee: number;
  title: string;
  subtitle: string;
  color: string;
  territories: string;
  mapUrl: string;
  imgUrls?: Array<string>;
  activationFee: number;
  transponderFee: number;
  hardwareFee: number;
  accountMaintenanceFee: number;
  firstVehicleTollBalance: number;
  additionalVehicleTollBalance: number;
  monthlyFees: number;
  details?: string;
  infoLink?: {
    text: string;
    url: string;
  };
  card?: string;
  bypassAddWarning?: string,
  processingFee: number;
  additionalMonthlyFees: Array<{
    name: string;
    fee: number;
    details: string;
    monthly: boolean;
  }>;
  ORDP?:{
    name?: string,
    fee?: number,
    details?: string,
    flatFee?: number
  };
  tollPlanId: number;
  bypassTollPlanId?: number;
  moreInfo?: string;
  regions?: {
    label: string;
    list:  {
      title:  string;
      states: string[];
  }[];
};
  legends?: Legends[],
  addOnIds?: number[];
}

export interface Legends {
  name: string;
  color: string;
  image?: string;
}
export interface Axles {
  axles?: number[];
  axleDefinitions?: AxleDefinition[];
}

export interface AxleDefinition {
  vehicleType: VehicleType | TrailerType;
  axles: number[];
}

export interface Tires {
  tires?: number[];
  tireDefinitions?: TireDefinition[];
}

export interface TireDefinition {
  vehicleType: VehicleType | TrailerType;
  tires: number[];
}

export interface OrdpPreExistingMultipleTicket {
  ordpPreExistingMultipleTickets: Array<{
    count: number;
  }>
}

export interface PageGroup {
  PageGroup: Array<{
    name: string;
  }>
}

export interface OrdpLink{
  ordpLogolink: string;
}

export interface StringPropertyObject {
  [prop: string]: string;
}

export interface Script {
  name: string;
  requiresReload: boolean;
}

export const DefaultScriptStore: Script[] = [
  {
    name: 'protectJS',
    requiresReload: true,
  },
];

export const registrationLink407ETR = 'https://www.407etr.com/en/tolls/transponders/transponder-equipped-vehicle.html';

export enum VendorIdConfig {
  itm = 'itm',
  oo = 'owner-op',
  efs = 'efs',
  mercer = 'mercer',
  bennett = 'bennett'
}

export enum A2BAssociationID{
  AtoB = "154",
  uberFreightCarrierCard = "155"
}

export type _associationDataAPIResponse = {
  associationId?: number;
  associationName?: string;
  imageContent?: string;
  isSpecialUrlAllowed?: boolean;
  errors?: unknown;
}